import React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';

import Layout from '../components/layout';
import Seo from '../components/seo';

import { Container } from 'react-grid-system';
import Hero from '../components/rows/hero/index';
import Follow from '../components/rows/cta/follow';
import Cta from '../components/rows/cta/index';

// PUT NAME OF BANNER IMAGE HERE
import Banner from '../images/hero-images/research-hero.jpg';

export default function ConditionsTreatments({ data }) {
  let list = [];
  data.allStrapiConditionTreatments.edges.forEach((e) => {
    list.push(e.node);
  });

  let alphaList = _.orderBy(list, ['title'], ['asc']);

  return (
    <Layout>
      <Seo
        title="Conditions / Treatments"
        description="Stay informed and learn about the various cardiac conditions & treatments. "
      />
      <Hero title="Conditions / Treatments" banner={Banner} />
      <Container className="my-52">
        <div className="specialist-intro text-center md:w-8/12 mx-auto">
          <h2>
            <span className="text-red-300">Cardiac</span> Conditions &amp;
            Treatments – Keeping Our Patients Informed
          </h2>
          <p>
            Navigate through the list below to discover a deeper understanding
            of the various heart diseases, conditions and treatments that will
            help patients and their families as they navigate their heart health
            journey.
          </p>
        </div>
      </Container>
      <div className="medical-bg bg-gray-100 py-52 flex flex-wrap mt-56 -my-52">
        <div className="ct-wrap">
          {alphaList.map((item, key) => (
            <a
              href={
                !item.pdf_doc
                  ? `/conditions-treatments/${item.slug}`
                  : item.pdf_doc.localFile.publicURL
              }
              key={key}
              className="text-red-500 featured-item"
              target={!item.pdf_doc ? '_self' : '_blank'}
              rel="noreferrer"
            >
              {item.title}
            </a>
          ))}
        </div>
      </div>
      <Cta />
      <Follow />
    </Layout>
  );
}

export const query = graphql`
  query ConditionsTreatments {
    allStrapiConditionTreatments {
      edges {
        node {
          title
          slug
          pdf_doc {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
